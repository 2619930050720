import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import config from '../utils/config'
import Icon from '../css/Icon';

const Events = ({ eventSource }) => {
    const [events, setEvents] = useState(null);
    const [loadMore, setLoadMore] = useState(false);
    const [done, setDone] = useState(false);

    useEffect(() => {
        if (!events)
            getEvents();
    }, []);

    const getEvents = async () => {
        try {
            const response = await fetch(`${config.hostApi}home/events`, {
                method: 'POST',
                body: JSON.stringify(eventSource),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status !== 200) {
                setDone(true);
                return;
            }
            const data = await response.json();
            const events = data.map((event) => {
                return {
                    title: ReactHtmlParser(event.title),
                    eventLink: event.eventLink,
                    eventDate: (new Date(event.eventDate).getMonth() + 1 < 10 ? '0' : '') +
                        (new Date(event.eventDate).getMonth() + 1) + '/' + (new Date(event.eventDate).getDate() < 10 ? '0' : '') +
                        (new Date(event.eventDate).getDate()) + '/' + new Date(event.eventDate).getFullYear()
                }
            })
            setEvents(events);
            setDone(true);
        }
        catch (err) {
            setDone(true);
            console.error(err);
        }
    };

    const handleLoadMore = () => {
        setLoadMore(true);
    }

    return (<div>
        <div className="indi-long-form-text h3" style={{ marginBottom: '10px' }}><Icon name="calendar" class="indi-icon--large" /> Upcoming Dates</div>
        {done === false && <div className="single-event-entry" style={{ color: 'darkgrey' }}>Loading upcoming dates...</div>}
        {done === true && (events === null || events?.length === 0) && <div className="single-event-entry" style={{ color: 'darkgrey' }}>No upcoming dates found.</div>}
        {loadMore === false && events
            && events.length > 3 &&
            <div>
                {events.slice(1, 4).map((event, index) =>
                    <div className="single-event-entry" key={index}>
                        <div className="row" >
                            <div className="col-sm-4 col-xs-3 text-center no-right-padding">
                                <div className="event-single-entry-date">
                                    <a title={event.title + " (opens in new window)"} aria-label={event.title} target="_blank" href={event.eventLink}>
                                        <div className="event-single-entry-dm">{event.eventDate.substring(0, event.eventDate.lastIndexOf('/'))}</div>
                                        <div className="event-single-entry-y">{event.eventDate.substring(event.eventDate.lastIndexOf('/') + 1, event.eventDate.length)}</div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-8 col-xs-9" style={{ textAlign: 'center' }}>
                                <a title={event.title + " (opens in new window)"} target="_blank" aria-label={event.title} href={event.eventLink}>
                                    <div className="single-event-entry-title">{event.title}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                )}
                <div className="single-event-entry" style={{ textAlign: 'right', border: 'none' }} >
                    <button aria-label="see full calendar" onClick={handleLoadMore} className="indi-button indi-button--tertiary indi-button--small send-code">
                        see full calendar
                    </button>
                </div>
            </div>
        }
        {events && (events.length <= 3 || loadMore === true)
            && events.map((event, index) =>
                <div className="single-event-entry" key={index}>
                    <div className="row" >
                        <div className="col-sm-4 col-xs-3 text-center no-right-padding">
                            <div className="event-single-entry-date">
                                <a title={event.title + " (opens in new window)"} target="_blank" aria-label={event.title} href={event.eventLink}>
                                    <div className="event-single-entry-dm">{event.eventDate.substring(0, event.eventDate.lastIndexOf('/'))}</div>
                                    <div className="event-single-entry-y">{event.eventDate.substring(event.eventDate.lastIndexOf('/') + 1, event.eventDate.length)}</div>
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-8 col-xs-9" style={{ textAlign: 'center' }}>
                            <a title={event.title + " (opens in new window)"} target="_blank" aria-label={event.title} href={event.eventLink}>
                                <div className="single-event-entry-title">{event.title}</div>
                            </a>
                        </div>
                    </div>
                </div>
            )}
    </div>
    );
};

export default Events;