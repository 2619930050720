import React, { Component } from 'react';

const NotAuthorized = ({ logout }) => {
    return (
        <div className="indi-modal indi-modal--is-open">
            <div className="indi-modal__content">
                <div className="indi-modal__header">
                    <div>Not Authorized</div>
                </div>
                <div className="indi-modal__body">
                    <div className="indi-long-form-text">
                        You are not authorized to access this system!
                    </div>
                </div>
                <div className="indi-modal__footer">
                    <a className="indi-button indi-button--primary" href={logout}> Continue </a>
                </div>
            </div>
        </div>
    );
};

export default NotAuthorized;