import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import config from '../utils/config';
import Instruction from './instructions';
import Primary from './login-primary';
import Verify from './mfa-verify';
import Enroll from './mfa-enroll';
import Recovery from './recovery-question';
import PasswordReset from './password-reset';
import ChangePassword from './password-expired';
import LockedOut from './account-lockout';
import PasswordWarn from './password-warn';
import Faq from './faq';

const LoginRouter = ({ baseUrl }) => {
    let navigate = useNavigate();
    const { oktaAuth } = useOktaAuth();
    const conf = config.oktaLoginStates;
    const [status, setStatus] = useState({
        transaction: null,
        cancel: false,
        token: window.location.pathname.split('reset-password/')[1],
        err: null
    });
    const [auth, setAuth] = useState(null);
    const [instruction, setInstruction] = useState(<div></div>);

    useEffect(() => {
        const initialize = () => {
            var tempAuth = <Primary baseUrl={baseUrl} transaction={status.transaction} transactionChange={transactionChange} />
            if (status.transaction) {
                switch (status.transaction.status) {
                    case conf.SUCCESS:
                        return oktaAuth.token.getWithRedirect({
                            sessionToken: status.transaction.sessionToken
                        });
                        break;
                    case conf.MFA_REQUIRED:
                    case conf.MFA_CHALLENGE:
                        tempAuth = <Verify transaction={status.transaction} transactionChange={transactionChange} />
                        break;
                    case conf.MFA_ENROLL:
                    case conf.MFA_ENROLL_ACTIVATE:
                        tempAuth = <Enroll transaction={status.transaction} transactionChange={transactionChange} />
                        break;
                    case conf.LOCKED_OUT:
                        tempAuth = <LockedOut transaction={status.transaction} canceltransaction={handleCancelLogin} />
                        break;
                    case conf.PASSWORD_WARN:
                        tempAuth = <PasswordWarn transactionSkip={transactionSkip} />
                        break;
                    case conf.PASSWORD_EXPIRED:
                        tempAuth = <ChangePassword transaction={status.transaction} transactionChange={transactionChange} />
                        break;
                    case conf.PASSWORD_RESET:
                        tempAuth = <PasswordReset baseUrl={baseUrl} transaction={status.transaction} transactionChange={transactionChange} />
                        break;
                    case conf.RECOVERY:
                        tempAuth = <Recovery transaction={status.transaction} transactionChange={transactionChange} canceltransaction={handleCancelLogin} />
                        break;
                    default:
                }
            }
            else if (status.token) {
                tempAuth = <PasswordReset baseUrl={baseUrl} recoveryToken={status.token} transactionChange={transactionChange} />;
            }
            else {
                setInstruction(<Instruction />);
            }
            setAuth(tempAuth);
        };
        initialize();
    }, [status])

    const transactionChange = (state, token = undefined) => {
        setStatus(prev => ({ ...prev, transaction: state, token: token }));
    };
    const transactionSkip = () => {
        status.transaction.skip().then(res => {
            setStatus(prev => ({ ...prev, transaction: res }));
        });
    };

    const handleCancelLogin = () => {
        try {
            status.transaction.cancel();
            return navigate('/signout')
        } catch (e) {
            console.log(e);
        }
    }


    return (<div>
        {instruction}
        <table style={{ margin: '0 auto' }}>
            <tbody>
                <tr>
                    <td>
                        <div data-se="auth-container" id="okta-sign-in" className="auth-container main-container no-beacon">
                            <div className="okta-sign-in-header auth-header">
                                <img src="https://forms.universalservice.org/Indigo/v1.2.0/images/usac-logo.png" className="auth-org-logo" style={{ maxHeight: '55px' }} alt="" />
                            </div>
                            <div className="auth-content" role="heading" aria-level="1">
                                {auth}
                                {status.transaction && status.transaction.status !== conf.LOCKED_OUT &&
                                    <div>
                                        <hr />
                                        <div style={{ textAlign: 'right' }}>
                                            <button
                                                className="indi-button indi-button--small indi-button--tertiary"
                                                onClick={() => handleCancelLogin()}>Sign out</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </td>
                    <td style={{ paddingLeft: '50pt', maxWidth: '335pt', verticalAlign: 'top', paddingTop: '90pt' }}>
                        <Faq />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    );
}

export default LoginRouter;

