import React, { useEffect } from 'react';
import Icon from '../css/Icon';
import { useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const Login = ({ baseUrl, transactionChange }) => {
    const base = document.getElementsByTagName('base')[0].href;
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const [username, setUsername] = useState('');
    const { oktaAuth } = useOktaAuth();
    const [validForm, setValidForm] = useState(true);
    const [password, setPassword] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ userNameError: '', passwordError: '', acceptTermsError: 'Please accept terms' });
    const [error, setError] = useState('');

    useEffect(() => {
        const validateForm = () => {
            let valid = true;
            Object.values(errors).forEach(
                (val) => val.length > 0 && (valid = false)
            );
            setValidForm(valid);
        };
        validateForm();
    }, [errors]);

    const handleUserNameChange = (event) => {
        let value = event.target.value;
        setErrors(prev => ({ ...prev, userNameError: validEmailRegex.test(value) ? '' : 'Username must be in email address form.' }));
        setUsername(value);
    };

    const handlePasswordChange = (event) => {
        let value = event.target.value;
        setErrors(prev => ({ ...prev, passwordError: value.length === 0 ? 'Password must be provided.' : '' }));
        setPassword(value);
    };

    const handleTermsChange = (event) => {
        let value = event.target.checked;
        setErrors(prev => ({ ...prev, acceptTermsError: value === true ? '' : 'Please accept terms.' }));
        setAcceptTerms(value);

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (username === '' || password === '') {
            setErrors({ userNameError: 'Please provide username.', passwordError: 'Please provide password.' });
            return;
        }
        if (validForm) {
            setLoading(true);
            oktaAuth.signInWithCredentials({
                username, password
            }).then(res => {
                transactionChange(res);
            }).catch((err) => {
                setLoading(false);
                setError(err.errorCode !== 'E0000004' ? err.errorSummary :
                    "You entered the wrong username and/or password.  Try again or click Forgot Password.  Note:  After five failed attempts, your account will be locked.");
            });
        }
    }


    return (
        <form onSubmit={handleSubmit}>
            <fieldset className="indi-form__fieldset">
                {
                    error && <div className="indi-form__input-row">
                        <div className="indi-notification indi-notification--error">
                            <Icon name="warning" class="indi-icon" />
                            {error}
                        </div>
                    </div>
                }
                <div className="indi-form__input-row">
                    <label className="indi-form__label">Username</label>
                    <input aria-label="username" name="username" className={"indi-form__input" + (errors.userNameError.length > 0 ? " indi-form__input--has-error" : "")} style={{ height: '40px' }} type="text" value={username} onChange={handleUserNameChange} />
                    <div className="indi-form__input-notification indi-form__input-notification--has-error">{errors.userNameError}</div>
                </div>
                <div className="indi-form__input-row">
                    <label className="indi-form__label">Password</label>
                    <input aria-label="password" name="password" className={"indi-form__input" + (errors.passwordError.length > 0 ? " indi-form__input--has-error" : "")} style={{ height: '40px' }} type="password" value={password} onChange={handlePasswordChange} />
                    <div className="indi-form__input-notification indi-form__input-notification--has-error">{errors.passwordError}</div>
                </div>
                <div className="indi-form__input-row">
                    <a href={baseUrl + "forgot-password"}>Forgot password?</a>
                </div>
                <div className="terms">
                    <br />
                    You are accessing a portal to Universal Service Administrative Company (USAC)
                    systems used to administer participation in the federal Universal Service programs in compliance with 47 C.F.R.
                    Part 54.  Access to the systems is provided solely to USAC-authorized users for USAC-authorized business purposes.
                    By logging in, you represent that you are an authorized user. Use of this system indicates acceptance of the terms
                    and conditions governing the USAC systems. USAC monitors user access and content for compliance with applicable laws
                    and policies.  Use of the system may be recorded, read, searched, copied and/or captured and is also subject to audit.
                    Unauthorized use or misuse of this system is strictly prohibited and subject to disciplinary and/or legal action.
                </div>
                <div className="indi-form__input-row">
                    <label className="indi-form__checkbox" htmlFor="acceptTerms">
                        <input id="acceptTerms" name="acceptTerms" aria-label="accept terms" type="checkbox" className="indi-form__checkbox-input" value={acceptTerms} onChange={handleTermsChange} />
                        <span className="indi-form__checkbox-icon"></span>
                        <span className="indi-form__checkbox-text" style={{ fontSize: '14px' }}><strong>Click the box to accept</strong> </span>
                    </label>
                </div>
                <p> By signing in, I accept the <a title="Terms and Conditions (opens in a new window)" href={base + "terms"} target="_blank">terms and conditions</a> of the USAC system.</p>
            </fieldset>
            {validForm &&
                <button className="indi-button indi-button--primary indi-button--small" type="submit">
                    {loading === true ?
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> : "Sign In"}
                </button>}
            {!validForm &&
                <button className="indi-button indi-button--primary indi-button--disabled indi-button--small" style={{ color: "#6c6c6c" }} type="submit">
                    Sign In
                </button>}
            <hr />
            <h5 style={{ color: '#404040', fontSize: '20px', fontWeight: '600' }}>Don't have an account? <a style={{fontSize: '20px', fontWeight: 'normal'}} href={base + "who"}> Create an account</a></h5>
        </form>
    );
}

export default Login;

