import React from 'react';

const modal = ({settings, redirect }) => {
    return (
        <div className="indi-modal indi-modal--is-open">
            <div className="indi-modal__content">
                <div className="indi-modal__header">
                    <div>Make Your Selection</div>
                </div>
                <div className="indi-modal__body">
                    <div className="indi-long-form-text">
                        <ul>
                            <li>Select <strong>Continue</strong> to go to your program's system.</li>
                            <li>Select <strong>Manage My Authentication Settings</strong> to update either your email address or phone number to access your account.</li>
                        </ul>
                    </div>
                </div>
                <div className="indi-modal__footer">
                    <a className="indi-button indi-button--tertiary" target="_blank" href={settings}>Manage My Authentication Settings </a>
                    <a className="indi-button indi-button--primary" href={redirect}> Continue </a>
                </div>
            </div>
        </div>
    );
};

export default modal;