import React, { useState } from 'react';

const Instructions = () => {
    const [closed, setClosed] = useState(false);
    const handleClose = () => {
        setClosed(true);
    }
    return (
        <div className="indi-long-form-text">
            <div className={"indi-modal" + (closed === false ? " indi-modal--is-open" : "")} style={{ paddingTop: '25px' }}>
                <div className="indi-modal__content" style={{ width: '900px', paddingTop: '10px', maxHeight: 'calc(100vh - 25px)', overflowY: 'auto' }}>
                    <div className="indi-modal__body program-body indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '26px' }}>
                        <div style={{ fontWeight: '400' }}><b>ACP Program providers, ACP Pilot Program Applicants, EPC users and BEAR Form filers: If this is your first time logging in to One Portal,
                            please read this information carefully. Also, for returning ACP Pilot Program Applicants, due to a recent system issue, you may need to reset your password in order to log in. If your existing password doesn't work, click "Forgot Password" to reset.</b></div> <br />
                        USAC has added multifactor authentication (MFA) to increase the security of our Universal Service Fund (USF) IT applications.
                        The first time you sign into an application, the system will prompt you to set up MFA for your account. To do this: <br /> <br />
                        <ol style={{ fontSize: '16px' }}>
                            <li data-index="1." style={{ margin: '0' }}>Click the blue <b>Continue</b> button below.</li>
                            <li data-index="2." style={{ margin: '0' }}>Click the <b>Forgot Password</b> link.</li>
                            <li data-index="3." style={{ margin: '0' }}>Enter your Username (your email address) and click <b>Reset via Email</b>.</li>
                            <li data-index="4." style={{ margin: '0' }}>When you receive the email, click the link to create a password.
                                Your password must be at least eight characters long and include one lowercase letter,
                                one uppercase letter, one number, and one special character.</li>
                            <li data-index="5." style={{ margin: '0' }}>Accept the system's terms of use and click <b>Sign In</b>.</li>
                            <li data-index="6." style={{ margin: '0' }}>On the next page, confirm the email associated with your account and click <b>Send Email</b>.</li>
                            <li data-index="7." style={{ margin: '0' }}>Check your email for a verification code.</li>
                            <li data-index="8." style={{ margin: '0' }}>Enter the code and click <b>Verify</b>.</li>
                        </ol> <br />
                        After logging in, you will see USAC's new single portal application dashboard if you have access to more than one application.
                        On this page you can access all of the USF applications associated with your log in. This means users no longer have to sign into multiple applications to
                        interact with different USF programs.<br /><br />
                        From the dashboard, you can visit the "Settings" page to add a cell phone number to your account.
                        This will allow you to receive verification codes via either email or text message when you log in in the future. <br /><br />
                        To learn more about USAC's MFA and to find a list of all USF IT applications included in the single portal,
                        visit <a title="USAC's website (opens in new window)" aria-label="USAC's website" target="_blank" href="https://www.usac.org/service-providers/resources/multi-factor-authentication/">USAC's website</a>.
                        If you cannot access the email associated with your account and need to set up a new log in, please contact your organization's USF administrator. If you need to reset your password,
                        click the "forgot password" link. More detailed instructions are available in <a title="Reset password video (opens in new window)" aria-label="Reset password video" target="_blank" href="https://player.vimeo.com/video/372673005">this video</a>.
                    </div>
                    <div className="indi-modal__footer" style={{ paddingBottom: '25px' }}>
                        <button className="indi-button indi-button--primary" onClick={handleClose}>Continue</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Instructions;
