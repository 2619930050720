import React, { Component } from 'react';
import { useEffect } from 'react';

const PasswordWarn = ({ transactionSkip }) => {
    useEffect(() => {
        transactionSkip();
    }, []);

    return (<div></div>);
};

export default PasswordWarn;

