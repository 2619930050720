import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Modal from './modal';
import config from '../utils/config'
import Loader from './loading';
import Unauthorized from './unauthorized';
import Header from './header';
import Landing from './landing';
import Terms from './terms-conditions';

const Home = () => {
    let navigate = useNavigate();
    const base = document.getElementsByTagName('base')[0].href;
    const [disclaimer, setDisclaimer] = useState(false);
    const [notauthorized, setNotauthorized] = useState(false);
    const [apps, setApps] = useState(null);

    const accessToken = localStorage.getItem("oktaAccessToken");

    useEffect(() => {
        if (!accessToken)
            return navigate('/login');
        if (!apps)
            getapps();

    }, []);


    const acceptDisclaimer = async () => {
        await fetch(`${config.hostApi}home/AcceptDisclaimer`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        setDisclaimer(true);
    }

    const cancelDisclaimer = async () => {
        setNotauthorized(true);
    }

    const getapps = async () => {
        try {
            const response = await fetch(`${config.hostApi}home/landing`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json'
                },
            });
            if (response.status !== 200) {
                return;
            }
            const data = await response.json();
            if (data.programs.length === 0) {
                setNotauthorized(true);
                return;
            }
            const apps = {
                programs: data.programs,
                eventLinks: data.eventLinks
            }
            setApps(apps);
            setDisclaimer(data.disclaimer);
        }
        catch (err) {
            console.error(err);
        }
        //setApps({ programs: config.sampleApps.programs, eventLinks: config.sampleApps.eventLinks });
        //setDisclaimer(true);
    }

    //if (this.returnTo !== undefined) {
    //    window.location.href = decodeURIComponent(this.returnTo);
    //}

    if ((apps === null /*|| userinfo == null*/) && !notauthorized) {
        return <Loader />;
    }
    if (notauthorized) {
        return <Unauthorized logout={base + "signout"} />;
    }
    if (apps) {
        if (!disclaimer) {
            return <Terms acceptDisclaimer={acceptDisclaimer} cancelDisclaimer={cancelDisclaimer} />;
        }
        if (apps.programs.length === 1 && apps.programs[0].programs.length === 1) {
            return <Modal redirect={apps.programs[0].programs[0].link}
                settings={config.signInConfig.baseUrl + "/enduser/settings?iframe=true&iframeControlHideAll=true"} />;
        }
    }
    return (<div id="main" role="heading" aria-level="1">
        <Header />
        <div className="indi-row">
            <div className="indi-col-lg-10 indi-col-lg-offset-1 indi-col-xs-12">
                <div style={{ marginRight: '2%' }}>
                    <Landing apps={apps} />
                    <hr />
                    <div className="footer-text">
                        <a title="Terms and Conditions (opens in a new window)" href={base + "terms"} target="_blank">Terms and conditions</a> | &copy;{new Date().getFullYear()} Universal Service Administrative Company. All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
export default Home;
