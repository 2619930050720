import React from 'react';

const faq = () => {
    return (<div className="auth-content">
        <fieldset className="indi-form__fieldset indi-long-form-text" style={{ background: '#d9ebff', borderStyle: 'none', fontSize: '12pt' }}>
            <h4>Trouble Logging In?</h4>
            <p>The <a title="USAC system dashboard (opens in a new window)" href="https://www.usac.org/usac-system-availability/status/" target="_blank">USAC system dashboard</a>
                &nbsp;displays known system issues and outages.</p>
            <p>If you experience issues logging in to USAC systems with Multifactor Authentication (MFA)
                and there is not a known system issue, most issues can be resolved by
                <strong> clearing your browser cache and allowing third party cookies. </strong>
                For more help, please visit the <a title="MFA troubleshooting (opens in a new window)"
                    href="https://www.usac.org/service-providers/resources/mfa-troubleshooting-faqs/" target="_blank">MFA Troubleshooting FAQs webpage.</a>
            </p>
        </fieldset>
    </div>);
};

export default faq;