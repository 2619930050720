import React from 'react';
import Icon from '../css/Icon';

const Locked = ({ canceltransaction }) => {

    const returnToLogin = () => {
        canceltransaction();
    };
    return (
        <div>
            <fieldset id="mfa-fieldset" className="indi-form__fieldset">
                <div className="indi-long-form-text h5 title">Account has been locked out!</div>
                <div className="indi-notification indi-notification--error" style={{ textAlign: 'justify' }}>
                    <Icon name="warning" class="indi-icon" />
                    Your account has been locked for 24 hours due to too many failed login attempts.
                    You may try to log in again in 24 hours, or you can choose to reset your password now.
                </div>
            </fieldset>
            <button name="submit" className="indi-button indi-button--primary indi-button--small" onClick={() => returnToLogin()}>Continue</button>
        </div>
    );
};

export default Locked;

