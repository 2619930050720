import React, { useEffect, useState } from 'react';
import config from '../utils/config';
import Icon from '../css/Icon';

const MFAVerify = ({ transaction, transactionChange }) => {
    const [passCode, setPassCode] = useState('');
    const [error, setError] = useState('');
    const [verror, setVerror] = useState('');
    const [loading, setLoading] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [selectedFactor, setSelectedFactor] = useState('');
    var smsFactor, emailFactor;

    useEffect(() => {
        try {
            if (selectedFactor === '') {
                smsFactor = transaction.factors.find(function (factor) {
                    return factor.provider === config.factors.provider && factor.factorType === config.factors.sms;
                });
                emailFactor = transaction.factors.find(function (factor) {
                    return factor.provider === config.factors.provider && factor.factorType === config.factors.email;
                });
                if (smsFactor === undefined && emailFactor === undefined) {
                    setError('You are enrolled in a second level user verification system that is currently not supported.');
                    return;
                }
                if (smsFactor === undefined || emailFactor === undefined) {
                    setSelectedFactor(smsFactor === undefined ? emailFactor : smsFactor);
                }
            }
        } catch (e) {
            console.log(e)
        }
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (passCode === '') {
            setVerror('Enter passcode.');
            return;
        }
        setLoading(true);
        transaction.verify({
            passCode: passCode.trim()
        }).then(res => transactionChange(res))
            .catch(err => {
                setLoading(false);
                setError(err.errorCode !== 'E0000068' ? err.errorSummary :
                    "Wrong passcode.");
            });

    }

    const handleSendPassCode = (event) => {
        event.preventDefault();
        selectedFactor.verify()
            .then(res => handleCodeSent(res))
            .catch(err => {
                setError(err.errorSummary)
            });
    };

    const handleCodeSent = (res) => {
        transactionChange(res);
        setCodeSent(true);
    };

    const handlePassCodeChange = (event) => {
        setPassCode(event.target.value);
    };

    const handleFactorSelected = (factor) => {
        setSelectedFactor(factor);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    if (selectedFactor) {
        return (
            <form onSubmit={handleSubmit}>
                {selectedFactor.factorType === config.factors.sms &&
                    <fieldset id="mfa-fieldset" className="indi-form__fieldset">
                        <div>
                            <div className="indi-long-form-text h5 title">Receive a passcode via text message</div>
                            <div className="desc">
                                USAC requires multifactor authentication to add an additional
                                layer of security when signing in to your account.
                            </div>
                        </div>
                        <br />
                        {
                            error && <div className="indi-form__input-row"> <br />
                                <div className="indi-notification indi-notification--error">
                                    <Icon name="warning" class="indi-icon" />
                                    {error}
                                </div>
                            </div>
                        }
                        {error === '' &&
                            codeSent && <div className="indi-form__input-row">
                                <div className="indi-notification indi-notification--success">
                                    <Icon name="success" class="indi-icon" />
                                    Passcode has been sent to the following phone number!
                                </div>
                            </div>
                        }
                        <div className="indi-form__input-row">&nbsp;</div>
                        <div className="indi-form__input-row">
                            <label className="indi-form__label">Phone number</label>
                            <input aria-label="phone number" id="phoneNumber" className="indi-form__input indi-form__input--disabled"
                                style={{ height: '40px' }} type="text"
                                value={selectedFactor.profile.phoneNumber} readOnly />
                        </div>
                        <div className="indi-form__input-row">&nbsp;</div>
                        {codeSent === false && <div className="indi-form__input-row">
                            <button onClick={handleSendPassCode} className="indi-button indi-button--primary indi-button--small send-code">
                                Send Passcode
                            </button>
                        </div>}
                        {codeSent === true && <div>
                            <div className="indi-form__input-row">
                                <div className="indi-form__input-group indi-form__input-group--1-2">
                                    <label className="indi-form__label">Enter passcode</label>
                                </div>
                            </div>
                            <div className="indi-form__input-row">
                                <div className="indi-form__input-group indi-form__input-group--1-2">
                                    <input aria-label="pass code" id="passCode" className={"indi-form__input" + (verror.length > 0 ? " indi-form__input--has-error" : "")}
                                        style={{ height: '40px' }} type="text"
                                        value={passCode}
                                        onChange={handlePassCodeChange} onKeyDown={handleKeyPress} />
                                    <div className="indi-form__input-notification indi-form__input-notification--has-error">{verror}</div>
                                </div>
                                <div className="indi-form__input-group indi-form__input-group--1-2">
                                    <button onClick={handleSendPassCode} className="indi-button indi-button--tertiary indi-button--small send-code">
                                        Re-send Passcode
                                    </button>
                                </div>
                            </div></div>}
                    </fieldset>}
                {selectedFactor.factorType === config.factors.email &&
                    <fieldset id="mfa-fieldset" className="indi-form__fieldset">
                        <div>
                            <div className="indi-long-form-text h5 title">Email Authentication</div>
                            <div className="desc">
                                USAC requires multifactor authentication to add an additional
                                layer of security when signing in to your account.
                            </div>
                        </div>
                        <br />
                        {
                            error && <div className="indi-form__input-row"> <br />
                                <div className="indi-notification indi-notification--error">
                                    <Icon name="warning" class="indi-icon" />
                                    {error}
                                </div>
                            </div>
                        }
                        {error === '' &&
                            codeSent && <div className="indi-form__input-row">
                                <div className="indi-notification indi-notification--success">
                                    <Icon name="success" class="indi-icon" />
                                    Passcode has been sent to the following email address!
                                </div>
                            </div>
                        }
                        <div className="indi-form__input-row">&nbsp;</div>
                        <div className="indi-form__input-row">
                            <label className="indi-form__label">Email Address</label>
                            <input aria-label="email address" id="emailAddress" className="indi-form__input indi-form__input--disabled"
                                style={{ height: '40px' }} type="text"
                                value={selectedFactor.profile.email} readOnly />
                        </div>
                        <div className="indi-form__input-row">&nbsp;</div>

                        {codeSent === false && <div className="indi-form__input-row">
                            <button onClick={handleSendPassCode} className="indi-button indi-button--primary indi-button--small send-code">
                                Send Email
                            </button>
                        </div>}
                        {codeSent === true && <div>
                            <div className="indi-form__input-row">
                                <div className="indi-form__input-group indi-form__input-group--1-2">
                                    <label className="indi-form__label">Enter passcode</label>
                                </div>
                            </div>
                            <div className="indi-form__input-row">
                                <div className="indi-form__input-group indi-form__input-group--1-2">
                                    <input aria-label="pass code" id="passCode" className={"indi-form__input" + (verror.length > 0 ? " indi-form__input--has-error" : "")}
                                        style={{ height: '40px' }} type="text"
                                        value={passCode}
                                        onChange={handlePassCodeChange} onKeyDown={handleKeyPress} />
                                    <div className="indi-form__input-notification indi-form__input-notification--has-error">{verror}</div>
                                </div>
                                <div className="indi-form__input-group indi-form__input-group--1-2">
                                    <button onClick={handleSendPassCode} className="indi-button indi-button--tertiary indi-button--small send-code">
                                        {codeSent === true ? "Re-send Email" : "Send Email"}
                                    </button>
                                </div>
                            </div>
                        </div>}
                    </fieldset>}
                {codeSent &&
                    <div className="btn-submit">
                        <button className="indi-button indi-button--primary indi-button--small" type="submit">
                            {loading === true ?
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : "Verify"}
                        </button>
                    </div>}
            </form>
        );
    }
    return (
        <fieldset id="mfa-fieldset" className="indi-form__fieldset">
            <div>
                <div className="indi-long-form-text h5 title">Select an Authentication Factor</div>
                <div className="desc">
                    USAC requires multifactor authentication to add an additional
                    layer of security when signing in to your account.
                </div>
            </div>
            <br />
            <div>
                {
                    error && <div className="indi-form__input-row"><br />
                        <div className="indi-notification indi-notification--error">
                            <Icon name="warning" class="indi-icon" />
                            {error}
                        </div>
                    </div>
                }
            </div>
            {error === '' && <div>
                <div>
                    <hr />
                    <div className="indi-form__input-row">
                        <button style={{ width: '100%', height: '100%' }} onClick={() => handleFactorSelected(smsFactor)} className="indi-button indi-button--tertiary">
                            <Icon name="chat" class="indi-icon--medium" />&nbsp;&nbsp;
                            <label>Text Message</label>
                        </button>
                    </div>
                </div>
                <hr />
                <div className="indi-form__input-row">
                    <button style={{ width: '100%', height: '100%' }} onClick={() => handleFactorSelected(emailFactor)} className="indi-button indi-button--tertiary">
                        <Icon name="email" class="indi-icon--medium" />&nbsp;&nbsp;
                        <label>Email</label>
                    </button>
                </div>
            </div>}
        </fieldset>
    );
};

export default MFAVerify;
