import React, { useEffect, useState } from 'react';
import Icon from '../css/Icon';

const Recovery = ({ transaction, canceltransaction, transactionChange }) => {
    const [answer, setAnswer] = useState('');
    const [res, setRes] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (transaction.user.recovery_question === null) { canceltransaction(); return; }
        setRes(transaction);

    }, [transaction])
    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        res.answer({
            answer: answer
        }).then(res => transactionChange(res))
            .catch(err => {
                setLoading(false);
                setError(err.errorSummary);
            });
    };
    const handleAnswerChange = (event) => {
        setAnswer(event.target.value);
    };
    const handleShow = (event) => {
        setShowAnswer(event.target.checked);
    };
    return (
        <form onSubmit={handleSubmit}>
            {res && <fieldset id="mfa-fieldset" className="indi-form__fieldset">
                <div className="indi-long-form-text h5 title">Answer Forgotten Password Challenge</div>
                {
                    error && <div className="indi-form__input-row">
                        <div className="indi-notification indi-notification--error">
                            <Icon name="warning" class="indi-icon" />
                            {error}
                        </div>
                    </div>
                }
                <div className="indi-form__input-row">&nbsp;</div>
                <div className="indi-form__input-row">
                    <label className="indi-form__label">{res.user.recovery_question.question}</label>
                    <input aria-label="answer" id="answer" className="indi-form__input"
                        style={{ height: '40px' }} type={showAnswer === true ? "text" : "password"}
                        value={answer} onChange={handleAnswerChange} />
                </div>
                <div className="indi-form__input-row">
                    <label className="indi-form__checkbox" htmlFor="showAnswer">
                        <input aria-label="show answer" id="showAnswer" name="showAnswer" type="checkbox" className="indi-form__checkbox-input" checked={showAnswer} onChange={handleShow} />
                        <span className="indi-form__checkbox-icon"></span>
                        <span className="indi-form__checkbox-text"><strong>Show</strong> </span>
                    </label>
                </div>
                <div className="indi-form__input-row">&nbsp;</div>
                <div className="btn-submit">
                    <button id="submit" className="indi-button indi-button--primary indi-button--small" style={{ minWidth: '160px' }} type="submit">
                        {loading === true ?
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : "Reset Password"}
                    </button>
                </div>
            </fieldset>
            }
        </form>
    );
};

export default Recovery;

