import React from 'react'
import icons from './indi_icons.svg'

const Icon = props => {
    return (
        <svg aria-label="icons" xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            className={`indi-icon ${props.class}`} style={props.style}>
            <use xlinkHref={`${icons}#${props.name}`} />
        </svg>
    )
}

export default Icon;