import React, { useEffect, useState } from 'react';
import config from '../utils/config';
import Icon from '../css/Icon';
var cities = require('country-json/src/country-by-calling-code.json');

const MFAEnroll = ({ transaction, transactionChange }) => {
    const [passCode, setPassCode] = useState('');
    const [error, setError] = useState('');
    const [verror, setVerror] = useState('');
    const [codeSent, setCodeSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [callingCode, setCallingCode] = useState('+1');
    const [phoneNumber, setPhoneNumber] = useState('+1');
    const [emailAddress, setEmailAddress] = useState('');
    const [selectedFactor, setSelectedFactor] = useState('');
    var smsFactor, emailFactor;

    useEffect(() => {
        try {
            if (selectedFactor === '') {
                smsFactor = transaction.factors.find(function (factor) {
                    return factor.provider === config.factors.provider && factor.factorType === config.factors.sms;
                });
                emailFactor = transaction.factors.find(function (factor) {
                    return factor.provider === config.factors.provider && factor.factorType === config.factors.email;
                });
                if (smsFactor === undefined && emailFactor === undefined) {
                    setError('You are enrolled in a second level user verification system that is currently not supported.');
                    return;
                }
                if (smsFactor === undefined || emailFactor === undefined) {
                    setSelectedFactor(smsFactor === undefined ? emailFactor : smsFactor);
                }
            }
        } catch (e) {
            console.log(e)
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        transaction.activate({
            passCode: passCode.trim()
        }).then(res => (res))
            .catch(err => {
                setLoading(false);
                setError(err.errorSummary);
            });
    };
    const handleCallingCodeChange = (event) => {
        setPhoneNumber('+' + event.target.value);
        setCallingCode('+' + event.target.value);
    };
    const handleCodeSent = (res) => {
        transactionChange(res);
        setCodeSent(true);
    };
    const handleSendPassCode = (event) => {
        event.preventDefault();
        if (passCode === '') {
            setVerror('Enter passcode.');
            return;
        }
        if (selectedFactor.factorType === config.factors.sms) {
            selectedFactor.enroll({
                profile: {
                    phoneNumber: phoneNumber,
                    updatePhone: true
                }
            }).then(res => handleCodeSent(res))
                .catch(err => {
                    setLoading(false);
                    setError(err.errorCode !== 'E0000068' ? err.errorSummary :
                        "Wrong passcode.");
                });
        }
    };
    const handlePhoneNumberChange = (event) => {
        if (callingCode === '+' + event.target.value || ('+' + event.target.value.length < callingCode.length))
            return;
        setPhoneNumber(event.target.value);
    };
    const handleEmailAddressChange = (event) => {
        setEmailAddress(event.target.value);
    };
    const handlePassCodeChange = (event) => {
        setPassCode(event.target.value);
    };
    const handleFactorSelected = (factor) => {
        setSelectedFactor(factor);
    };
    if (selectedFactor) {
        return (
            <form onSubmit={handleSubmit}>
                {selectedFactor.factorType === config.factors.sms &&
                    <fieldset id="mfa-fieldset" className="indi-form__fieldset">
                        <div>
                            <div className="indi-long-form-text h5 title">Receive a passcode via text message</div>
                            <div className="desc">
                                USAC requires multifactor authentication to add an additional
                                layer of security when signing in to your account.
                            </div>
                        </div>
                        <br />
                        {
                            error && <div className="indi-form__input-row"><br />
                                <div className="indi-notification indi-notification--error">
                                    <Icon name="warning" class="indi-icon" />
                                    {error}
                                </div>
                            </div>
                        }
                        {error === '' &&
                            codeSent && <div className="indi-form__input-row"><br />
                                <div className="indi-notification indi-notification--success">
                                    <Icon name="success" class="indi-icon" />
                                    Passcode has been sent to the following phone number!
                                </div>
                            </div>
                        }
                        <div className="indi-form__input-row">&nbsp;</div>
                        <div className="indi-form__input-row">
                            <div className="indi-form__select">
                                <select aria-label="country"
                                    onChange={handleCallingCodeChange}
                                    className="indi-form__select-input">
                                    <option className="indi-form__select-option" value="">Select an option</option>
                                    {(cities.filter(c => c.calling_code)).map(city =>
                                        <option className="indi-form__select-option" selected={city.country === "United States" ? "selected" : ""}
                                            value={city.calling_code}>{city.country}</option>
                                    )}
                                </select>
                                <Icon name="caret-down" class="indi-icon indi-form__select-open-icon" />
                            </div>
                        </div>
                        <div className="indi-form__input-row">&nbsp;</div>
                        <div className="indi-form__input-row">
                            <label className="indi-form__label">Phone number</label>
                            <input aria-label="phone number" id="phoneNumber" className="indi-form__input"
                                style={{ height: '40px' }} type="tel"
                                value={phoneNumber} required=""
                                onChange={handlePhoneNumberChange} />
                        </div>
                        <div className="indi-form__input-row">&nbsp;</div>
                        {codeSent === false && codeSent === false && <div className="indi-form__input-row">
                            <button onClick={handleSendPassCode} className="indi-button indi-button--primary indi-button--small send-code">
                                Send Passcode
                            </button>
                        </div>}
                        {codeSent === true && <div>
                            <div className="indi-form__input-row">
                                <div className="indi-form__input-group indi-form__input-group--1-2">
                                    <label className="indi-form__label">Enter passcode</label>
                                </div>
                            </div>
                            <div className="indi-form__input-row">
                                <div className="indi-form__input-group indi-form__input-group--1-2">
                                    <input aria-label="pass code" id="passCode" className={"indi-form__input" + (verror.length > 0 ? " indi-form__input--has-error" : "")}
                                        style={{ height: '40px' }} type="text"
                                        value={passCode}
                                        onChange={handlePassCodeChange} />
                                    <div className="indi-form__input-notification indi-form__input-notification--has-error">{verror}</div>
                                </div>
                                <div className="indi-form__input-group indi-form__input-group--1-2">
                                    <button onClick={handleSendPassCode} className="indi-button indi-button--tertiary indi-button--small send-code">
                                        Re-send Passcode
                                    </button>
                                </div>
                            </div></div>}
                    </fieldset>}
                {selectedFactor.factorType === config.factors.email &&
                    <fieldset id="mfa-fieldset" className="indi-form__fieldset">
                        <div>
                            <div className="indi-long-form-text h5 title">Email Authentication</div>
                            <div className="desc">
                                USAC requires multifactor authentication to add an additional
                                layer of security when signing in to your account.
                            </div>
                        </div>
                        <br />
                        {
                            error && <div className="indi-form__input-row"> <br />
                                <div className="indi-notification indi-notification--error">
                                    <Icon name="warning" class="indi-icon" />
                                    {error}
                                </div>
                            </div>
                        }
                        {error === '' &&
                            codeSent && <div className="indi-form__input-row">
                                <div className="indi-notification indi-notification--success">
                                    <Icon name="success" class="indi-icon" />
                                    Passcode has been sent to the following email address!
                                </div>
                            </div>
                        }
                        <div className="indi-form__input-row">
                            <label className="indi-form__label">Email Address</label>
                            <input aria-label="email address" id="emailAddress" className="indi-form__input"
                                style={{ height: '40px' }} type="text"
                                value={emailAddress}
                                onChange={handleEmailAddressChange} />
                        </div>
                        <div className="indi-form__input-row">&nbsp;</div>
                        {codeSent === false && <div className="indi-form__input-row">
                            <button onClick={handleSendPassCode} className="indi-button indi-button--primary indi-button--small send-code">
                                Send Email
                            </button>
                        </div>}
                        {codeSent === true && <div><div className="indi-form__input-row">
                            <div className="indi-form__input-group indi-form__input-group--1-2">
                                <label className="indi-form__label">Enter passcode</label>
                            </div>
                        </div>
                            <div className="indi-form__input-row">
                                <div className="indi-form__input-group indi-form__input-group--1-2">
                                    <input aria-label="pass code" id="passCode" className={"indi-form__input" + (verror.length > 0 ? " indi-form__input--has-error" : "")}
                                        style={{ height: '40px' }} type="text"
                                        value={passCode}
                                        onChange={handlePassCodeChange} />
                                    <div className="indi-form__input-notification indi-form__input-notification--has-error">{verror}</div>
                                </div>
                                <div className="indi-form__input-group indi-form__input-group--1-2">
                                    <button onClick={handleSendPassCode} className="indi-button indi-button--tertiary indi-button--small send-code">
                                        Re-send Email
                                    </button>
                                </div>
                            </div>
                        </div>}

                    </fieldset>}
                {codeSent &&
                    <div className="btn-submit">
                        <button className="indi-button indi-button--primary indi-button--small" type="submit">
                            {loading === true ?
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : "Set up"}
                        </button>
                    </div>}
            </form>
        );
    }
    return (
        <fieldset id="mfa-fieldset" className="indi-form__fieldset">
            <div>
                <div className="indi-long-form-text h5 title">Select an Authentication Factor</div>
                <div className="desc">
                    USAC requires multifactor authentication to add an additional
                    layer of security when signing in to your account.
                </div>
            </div>
            <div className="indi-form__input-row">&nbsp;</div>
            <div className="indi-form__input-row">
                Setup required
            </div>
            <hr />
            {smsFactor.status === config.factors.status.active &&
                <div className="indi-form__input-row">
                    <div className="indi-col-xs-6" style={{ padding: '0', fontSize: '16px', color: 'grey' }}>
                        <Icon name="chat" class="indi-icon indi-icon--medium indi-icon--disabled" style={{ fill: 'grey' }} />&nbsp;&nbsp;<label>Text Message</label>
                    </div>
                    <div className="indi-col-xs-6" style={{ textAlign: 'right' }}>
                        <Icon name="success" class="indi-icon indi-icon--medium indi-icon--disabled" />
                    </div>
                </div>
            }
            {smsFactor.status !== config.factors.status.active &&
                <div className="indi-form__input-row">
                    <button style={{ width: '100%', height: '100%', background: 'transparent' }} onClick={() => handleFactorSelected(smsFactor)} className="indi-button indi-button--tertiary">
                        <Icon name="chat" class="indi-icon indi-icon--medium" /> <label>Text Message</label>
                    </button>
                </div>}
            <hr />
            {emailFactor.status === config.factors.status.active &&
                <div className="indi-form__input-row">
                    <div className="indi-col-xs-6" style={{ padding: '0', fontSize: '16px', color: 'grey' }}>
                        <Icon name="email" class="indi-icon indi-icon--medium indi-icon--disabled" style={{ fill: 'grey' }} />&nbsp;&nbsp;<label>Email</label>
                    </div>
                    <div className="indi-col-xs-6" style={{ textAlign: 'right' }} >
                        <Icon name="success" style={{ fill: 'grey' }} class="indi-icon indi-icon--medium indi-icon--disabled" />
                    </div>
                </div>
            }
            {emailFactor.status !== config.factors.status.active &&
                <div className="indi-form__input-row">
                    <button style={{ width: '100%', height: '100%', background: 'transparent' }} onClick={() => handleFactorSelected(emailFactor)} className="indi-button indi-button--tertiary">
                        <Icon name="email" class="indi-icon indi-icon--medium" /> <label> Email</label>
                    </button>
                </div>}
            <hr />
        </fieldset>
    );
};

export default MFAEnroll;

