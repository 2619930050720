import React, { useState, Component } from 'react';
import Icon from '../css/Icon';
import config from '../utils/config'
import { Navigate } from 'react-router-dom';

const Header = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const [logout, setLogout] = useState();
    const signOut = () => {
        setLogout(true);
    };
    if (logout) {
        return <Navigate to={{ pathname: '/signout' }} />
    }
    return (
        <div className="indi-row" style={{ marginBottom: '2%' }}>
            <header className="indi-doc-header">
                <div className="indi-doc-header__banner">
                    <div className="indi-doc-layout-container">
                        <div className="indi-row">
                            <div className="indi-col-xs-2 indi-col-xs-offset-1">
                                <a className="indi-doc-header__link" href={document.getElementsByTagName('base')[0].href}>
                                    <img alt="Universal Service Administrative Company Logo" className="indi-doc-header__logo"
                                        src="https://forms.universalservice.org/Indigo/v1.2.0/images/usac-logo-horizontal-white.png"
                                        style={{ border: 'none' }}></img>
                                </a>
                            </div>
                            {userInfo && < div className="indi-col-lg-8 indi-col-xs-9">
                                <div className="indi-doc-header__title">
                                    <span></span>
                                </div>
                                <div className="indi-col-xs-12" style={{ fontSize: '16px' }}>
                                    <div style={{ textAlign: 'right' }}>
                                        <button className="indi-button indi-button-teritiary indi-button--small" onClick={signOut}>Sign Out</button>
                                    </div></div>
                            </div>}
                        </div>
                    </div>
                </div>
            </header>
            <div className="indi-row">
                <div className="indi-col-lg-8 indi-col-md-8 indi-col-sm-6 indi-col-xs-6"></div>
                <div className=" indi-col-lg-3 indi-col-md-4 indi-col-sm-6 indi-col-xs-6">
                    <div style={{ marginTop: '2%', paddingRight: '15px' }}>
                        {userInfo && <div className="indi-form__select" style={{ textAlign: 'right', border: 'none' }}>
                            <CustomCard user={userInfo.preferred_username} setting={config.signInConfig.baseUrl + "/enduser/settings?iframe=true&iframeControlHideAll=true"} />
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;

class CustomCard extends Component {
    constructor() {
        super();

        this.state = {
            showMenu: false,
        };

        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    showMenu(event) {
        event.preventDefault();

        this.setState({ showMenu: true }, () => {
            document.addEventListener('click', this.closeMenu);
        });
    }

    closeMenu(event) {

        if (!this.dropdownMenu.contains(event.target)) {

            this.setState({ showMenu: false }, () => {
                document.removeEventListener('click', this.closeMenu);
            });

        }
    }

    render() {
        return (
            <div className="dropdown indi-form__select">
                <button className="indi-form__select-input" style={{ border: 'none', fontSize: '15px', paddingLeft: '0', textAlign: 'right' }} onClick={this.showMenu}>
                    {this.props.user} <Icon name="caret-down" class="indi-form__select-open-icon" />
                </button>
                {this.state.showMenu ? (
                    <div className="dropdown-menu indi-form__select-input show"
                        ref={(element) => {
                            this.dropdownMenu = element;
                        }}>
                        <a className="dropdown-item" href={this.props.setting} target='_blank' style={{ textAlign: 'right' }}>Settings</a>
                    </div>) : (null)
                }
            </div>
        );
    }
}